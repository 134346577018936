import { useTransContent } from "modules/common/trans-content/transContent";
import { useMemo } from "react";

export default function useBookTripSelect() {
  const { t } = useTransContent();
  return useMemo(
    () => ({
      trip: t("web.home.bookATrip.trip"),
      passengers: t("web.home.bookATrip.passengers"),
      passengersAdult: t("web.home.bookATrip.passengers.adults"),
      passengersChild: t("web.home.bookATrip.passengers.children"),
      passengersChildTip: t("web.home.bookATrip.passengers.childTip"),
      passengersChildToolTip: t("web.home.bookATrip.passengers.childToolTip"),
      passengersInfant: t("web.home.bookATrip.passengers.infants"),
      passengersInfantTip: t("web.home.bookATrip.passengers.infantTip"),
      passengersPopUpTitle: t("web.home.bookATrip.passengers.popUpTitle"),
      promoCodeOptional: t("web.home.bookATrip.promoCodeOptional"),
      date: t("web.home.bookATrip.date"),
      chooseTravelDates: t("web.home.bookATrip.chooseTravelDates"),
      departDate: t("web.home.bookATrip.departDate"),
      returnDate: t("web.home.bookATrip.returnDate"),
      departDateTrip1: t("web.home.bookATrip.departDateTrip1"),
      departDateTrip2: t("web.home.bookATrip.departDateTrip2"),
      search: t("web.home.bookATrip.search"),
      recentSearches: t("web.home.bookATrip.recentSearches"),
      multiCity: t("web.home.bookATrip.multiCity"),
      tripOptions: {
        roundTrip: t("web.home.bookATrip.roundTrip"),
        oneWay: t("web.home.bookATrip.oneWay"),
        multiCity: t("web.home.bookATrip.multiCity"),
      },
      fromRequired: t("web.home.bookATrip.from.required"),
      toRequired: t("web.home.bookATrip.from.toRequired"),
      dateRequired: t("web.home.bookATrip.dateRequired"),
      departDateRequired: t("web.home.bookATrip.departDate.required"),
      returnDateRequired: t("web.home.bookATrip.returnDate.required"),
      multiCityFrom1Required: t("web.home.bookATrip.multiCityFrom1.required"),
      multiCityTo1Required: t("web.home.bookATrip.multiCityTo1.required"),
      multiCityDepartDateRequired: t("web.home.bookATrip.multiCityDepartDate.required"),
      multiCityFrom2Required: t("web.home.bookATrip.multiCityFrom2.required"),
      multiCityTo2Required: t("web.home.bookATrip.multiCityTo2.required"),
      multiCityReturnDateRequired: t("web.home.bookATrip.multiCityReturnDate.required"),
      dateErrorMessage: t("web.home.bookATrip.dateErrorMessage"),
      from: t("web.home.bookATrip.from"),
      to: t("web.home.bookATrip.to"),
      airportCode: {
        HK: t("marketCodeName.HK"),
        HKG: t("airportCodeToCityName.HKG"),
        CN: t("marketCodeName.CN"),
        FYG: t("airportCodeToCityName.FYG"),
        NGB: t("airportCodeToCityName.NGB"),
        NSZ: t("airportCodeToCityName.NSZ"),
        PFT: t("airportCodeToCityName.PFT"),
        PKX: t("airportCodeToCityName.PKX"),
        SYX: t("airportCodeToCityName.SYX"),
        ZGN: t("airportCodeToCityName.ZGN"),
        ZTI: t("airportCodeToCityName.ZTI"),
        ZYK: t("airportCodeToCityName.ZYK"),
        JP: t("marketCodeName.JP"),
        FUK: t("airportCodeToCityName.FUK"),
        HND: t("airportCodeToCityName.HND"),
        KIX: t("airportCodeToCityName.KIX"),
        KMJ: t("airportCodeToCityName.KMJ"),
        KOJ: t("airportCodeToCityName.KOJ"),
        NGO: t("airportCodeToCityName.NGO"),
        OKA: t("airportCodeToCityName.OKA"),
        NRT: t("airportCodeToCityName.NRT"),
        TAK: t("airportCodeToCityName.TAK"),
        TYO: t("airportCodeToCityName.TYO"),
        KR: t("marketCodeName.KR"),
        CJU: t("airportCodeToCityName.CJU"),
        ICN: t("airportCodeToCityName.ICN"),
        PUS: t("airportCodeToCityName.PUS"),
        PH: t("marketCodeName.PH"),
        TH: t("marketCodeName.TH"),
        BK1: t("airportCodeToCityName.BK1"),
        BKK: t("airportCodeToCityName.BKK"),
        KHH: t("airportCodeToCityName.KHH"),
        DMK: t("airportCodeToCityName.DMK"),
        CMX: t("airportCodeToCityName.CMX"),
        HKT: t("airportCodeToCityName.HKT"),
        TW: t("marketCodeName.TW"),
        RMQ: t("airportCodeToCityName.RMQ"),
        TPE: t("airportCodeToCityName.TPE"),
        CRK: t("airportCodeToCityName.CRK"),
        MNL: t("airportCodeToCityName.MNL"),
        CNX: t("airportCodeToCityName.CNX"),
        VN: t("marketCodeName.VN"),
        CXR: t("airportCodeToCityName.CXR"),
        DAD: t("airportCodeToCityName.DAD"),
        HAN: t("airportCodeToCityName.HAN"),
        MY: t("marketCodeName.MY"),
        CZX: t("airportCodeToCityName.CZX"),
        HIJ: t("airportCodeToCityName.HIJ"),
        YIW: t("airportCodeToCityName.YIW"),
        KIJ: t("airportCodeToCityName.KIJ"),
        HUN: t("airportCodeToCityName.HUN"),
        PEN: t("airportCodeToCityName.PEN"),
        SDJ: t("airportCodeToCityName.SDJ"),
        PQC: t("airportCodeToCityName.PQC"),
        KH: t("marketCodeName.KH"),
        MP: t("marketCodeName.MP"),
        SG: t("marketCodeName.SG"),
      },
    }),
    [t]
  );
}
