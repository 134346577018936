import { Booking, convertSearchParams } from "@hkexpressairwayslimited/ui";
import { ESearchingType } from "[lang]/flight-status/d";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { getFlightRoute } from "lib/features/flight-book/booking/service";
import useBookTripSelect from "modules/common/hooks/useBookTripSelect";
import { showLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { useTransContent } from "modules/common/trans-content/transContent";
import { selectTokenProfile } from "modules/features/member/selector";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterInstance } from "router/router-utils";
import { selectRouteMapping, storeFlightRouteMapping } from "store/sessionStorage/slices/flightRouteMapping";

export function BookingBox() {
  const { t } = useTransContent();
  const flightRouteMapping = useSelector(selectRouteMapping);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!flightRouteMapping.flight_route_mappings.length || !flightRouteMapping.sales_port_grouping.length) {
      getFlightRoute().then((response) => {
        dispatch(storeFlightRouteMapping(response));
      });
    }
  }, [dispatch, flightRouteMapping]);
  const tokenProfile = useSelector(selectTokenProfile);
  const [accessToken, setAccessToken] = useState(Cookies.get("access_token"));
  useEffect(() => {
    setAccessToken(Cookies.get("access_token"));
  }, [tokenProfile]);

  const handleBookingTripSubmit = (data: any) => {
    dispatch(showLoadingBackdrop());
    // const query = convertSearchParams({...data,campaignCode:'agent1'});
    const query = convertSearchParams(data);
    // dispatch(storeFlightSearching(data));

    RouterInstance.push(`/:lang/flight-booking/select?${query}`);
  };
  const handleManageMyBookingSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;
    sessionStorage.setItem(
      "tripParams",
      JSON.stringify({
        bookingRef: BookingRef,
        firstName: FirstName,
        lastName: LastName,
      })
    );
    const params = new URLSearchParams({ bookingRef: BookingRef, firstName: FirstName, lastName: LastName });
    RouterInstance.push(`/:lang/manage-my-booking/my-trips/${BookingRef}?${params}`);
  };
  const handleManageMyBookingLink = () => {
    RouterInstance.push("/:lang/manage-my-booking/my-trips");
  };
  const handleCheckInSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;
    sessionStorage.setItem(
      "tripParams",
      JSON.stringify({
        bookingRef: BookingRef,
        firstName: FirstName,
        lastName: LastName,
      })
    );
    const params = new URLSearchParams({ bookingRef: BookingRef, firstName: FirstName, lastName: LastName });
    RouterInstance.push(`/:lang/manage-my-booking/my-trips/${BookingRef}?${params}`);
  };
  const handleFlightStatusSubmit = (formValues: any) => {
    const { SearchBy, FlightNumber, DepartDate, From, To } = formValues.data;
    let url = `/:lang/flight-status?searchBy=${SearchBy}&departure_date=${format(DepartDate[0], "yyyy-MM-dd")}`;
    url +=
      SearchBy === ESearchingType.FlightNumber
        ? `&flight_number=${FlightNumber}`
        : `&departure_station=${From}&arrival_station=${To}`;
    dispatch(showLoadingBackdrop());
    RouterInstance.push(url);
  };
  const i18nContent = {
    booking: {
      bookATrip: t("web.home.bookATrip"),
      manageMyBooking: t("web.home.manageMyBooking"),
      checkIn: t("web.home.checkIn"),
      flightStatus: t("web.home.flightStatus"),
    },
    bookTrip: useBookTripSelect(),
    manageBooking: {
      bookingReferenceRequired: t("web.home.bookingReference.required"),
      lastNameRequired: t("web.home.lastName.required"),
      firstNameRequired: t("web.home.firstName.required"),
      text: t("web.home.manageBooking.text"),
      bookingReference: t("web.home.manageBooking.bookingReference"),
      lastName: t("web.home.manageBooking.lastName"),
      firstName: t("web.home.manageBooking.firstName"),
      manageBooking: t("web.home.manageBooking.manageBooking"),
      retrieveMyTrip: t("web.home.manageBooking.retrieveMyTrip"),
    },
    flightStatus: {
      flightNumber: t("web.home.flightStatus.flightNumber"),
      route: t("web.home.flightStatus.route"),
      text: t("web.home.flightStatus.text"),
      fromRequired: t("web.home.flightStatus.from.required"),
      toRequired: t("web.home.flightStatus.to.required"),
      flightNumberRequired: t("web.home.flightStatus.flightNumber.required"),
      departDateRequired: t("web.home.flightStatus.departDate.required"),
      departDate: t("web.home.flightStatus.departDate"),
      searchBy: t("web.home.flightStatus.searchBy"),
      search: t("web.home.flightStatus.search"),
    },
    checkIn: {
      bookingReferenceRequired: t("web.home.bookingReference.required"),
      lastNameRequired: t("web.home.lastName.required"),
      firstNameRequired: t("web.home.firstName.required"),
      text: t("web.home.checkIn.text"),
      bookingReference: t("web.home.checkIn.bookingReference"),
      lastName: t("web.home.checkIn.lastName"),
      firstName: t("web.home.checkIn.firstName"),
      checkIn: t("web.home.checkIn.checkIn"),
    },
  };
  return (
    <Booking
      pageType='IBE'
      handleBookingTripSubmit={handleBookingTripSubmit}
      handleManageMyBookingSubmit={handleManageMyBookingSubmit}
      handleManageMyBookingLink={handleManageMyBookingLink}
      handleCheckInSubmit={handleCheckInSubmit}
      handleFlightStatusSubmit={handleFlightStatusSubmit}
      flightRouteOptions={flightRouteMapping}
      i18nContent={i18nContent}
      paxMax={9}
      hasLogin={!!accessToken}
    />
  );
}
